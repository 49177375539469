import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cruiseAPI, cruiseLineAPI, eventAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { EditedEventType, EventListFetchParamsType, EventType } from '../types/eventTypes'
import { CruiseLineType, CruiseStartDateType, CruiseType } from '../types/cruiseType'
import { uniqBy } from 'lodash'

const eventListFetchParamsDefaultValue: EventListFetchParamsType = {
  pagination: {page: 1, size: 10 as 10},
  filter: {
    search_value: '',
    event_kind: null,
    event_type: null
  }
}

interface InitialStateType {
  eventList: null | EventType[]
  eventListTotalCount: number
  eventListFetchParams: EventListFetchParamsType
  currentEvent: null | EventType

  cruiseOptions: CruiseType[] | null
  cruiseLineOptions: CruiseLineType[] | null
  cruiseDateOptions: CruiseStartDateType[] | null
}

const initialState: InitialStateType = {
  eventList: null,
  eventListTotalCount: 0,
  eventListFetchParams: eventListFetchParamsDefaultValue,
  currentEvent: null,

  cruiseOptions: null,
  cruiseLineOptions: null,
  cruiseDateOptions: null
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventList: (state, action: PayloadAction<null | EventType[]>) => {state.eventList = action.payload},
    setEventListTotalCount: (state, action: PayloadAction<number>) => {state.eventListTotalCount = action.payload},
    setEventListFetchParams: (state, action: PayloadAction<EventListFetchParamsType>) => {state.eventListFetchParams = action.payload},
    setCurrentEvent: (state, action: PayloadAction<null | EventType>) => {state.currentEvent = action.payload},
   
    setCruiseOptions: (state, action: PayloadAction<CruiseType[] | null>) => {state.cruiseOptions = action.payload},
    setCruiseLineOptions: (state, action: PayloadAction<CruiseLineType[] | null>) => {state.cruiseLineOptions = action.payload},
    setCruiseDateOptions: (state, action: PayloadAction<CruiseStartDateType[] | null>) => {state.cruiseDateOptions = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetEventListThunk.fulfilled, (state, action) => {
        state.eventList = action.payload.events
        state.eventListTotalCount = action.payload.total_count
      })
      .addCase(GetEventByCodeThunk.fulfilled, (state, action) => {
        state.currentEvent = action.payload
      })
      .addCase(EditEventThunk.fulfilled, (state, action) => {
        state.currentEvent = action.payload
      })
      .addCase(DeleteEventThunk.fulfilled, (state, action) => {
        state.eventList = state?.eventList?.filter(event => event.code !== String(action.payload)) || []
        state.eventListTotalCount = state?.eventListTotalCount - 1 || 0
      })
      .addCase(GetCruisesByLineIdThunk.fulfilled, (state, action) => {
        state.cruiseOptions = action.payload
      })
      .addCase(GetCruiseLineOptionsThunk.fulfilled, (state, action) => {
        state.cruiseLineOptions = action.payload
      })
      .addCase(GetCruiseAvailableDatesThunk.fulfilled, (state, action) => {
        state.cruiseDateOptions = uniqBy(action.payload, (opt => opt.departure_date))
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.eventList = null
        state.eventListTotalCount = 0
        state.eventListFetchParams = eventListFetchParamsDefaultValue
        state.cruiseOptions = null
        state.cruiseLineOptions = null
        state.cruiseDateOptions = null
      })
  }
})

export const {
  setEventList,
  setEventListTotalCount,
  setEventListFetchParams,
  setCurrentEvent,
  setCruiseOptions,
  setCruiseLineOptions,
  setCruiseDateOptions
} = eventSlice.actions

export const selectEventList = (state: RootState): null | EventType[] => state.event.eventList
export const selectEventListTotalCount = (state: RootState): number => state.event.eventListTotalCount
export const selectEventListFetchParams = (state: RootState): EventListFetchParamsType => state.event.eventListFetchParams
export const selectCurrentEvent = (state: RootState): null | EventType => state.event.currentEvent
export const selectCruiseOptions = (state: RootState): CruiseType[] | null => state.event.cruiseOptions
export const selectCruiseLineOptions = (state: RootState): CruiseLineType[] | null => state.event.cruiseLineOptions
export const selectCruiseDateOptions = (state: RootState): CruiseStartDateType[] | null => state.event.cruiseDateOptions

export const GetEventListThunk = createAsyncThunk<{events: EventType[], total_count: number}, {fetchParams: EventListFetchParamsType, source: any}, AsyncThunkConfig>(
  'event/getEventList',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      !!fetchParams?.pagination && (formData.append('pagination', new Blob([JSON.stringify(fetchParams.pagination, null, 2)], {type: 'application/json'})))
      formData.append('filter', new Blob([JSON.stringify(fetchParams.filter, null, 2)], {type: 'application/json'}))
      const { status, data } = await eventAPI.getEventList(formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetEventByCodeThunk = createAsyncThunk<EventType, number, AsyncThunkConfig>(
  'event/getEventByCode',
  async (eventCode, thunkAPI) => {
    try {
      const { status, data } = await eventAPI.getEventByCode(eventCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)


export const EditEventThunk = createAsyncThunk<EventType, {code: number, eventData: EditedEventType}, AsyncThunkConfig>(
  'event/editEvent',
  async ({code, eventData}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('event', new Blob([JSON.stringify(eventData.event, null, 2)], {type: 'application/json'}))
      eventData?.add_photos && eventData?.add_photos.forEach(photo => formData.append('add_photos', photo.originFileObj))
      eventData?.delete_photos?.length && formData.append('delete_photos', new Blob([JSON.stringify({urls: eventData?.delete_photos}, null, 2)], {type: 'application/json'}))
      const { status, data } = await eventAPI.editEvent(code, formData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Event has been edited successfully'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteEventThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'event/deleteEvent',
  async (code, thunkAPI) => {
    try {
      const { status, data } = await eventAPI.deleteEvent(code)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(code, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetCruisesByLineIdThunk = createAsyncThunk<
  CruiseType[],
  {
    lineId: number,
    data: {
      cruise_date?: string
      cruise_name?: string
      code_to_cruise_id?: string
      with_cruise_routes?: boolean
    },
    source: any
  },
  AsyncThunkConfig
>(
  'event/getCruisesByLineId',
  async (requestData, thunkAPI) => {
    try {
      const {data, status} = await cruiseAPI.getCruiseByLineAndDate(requestData.lineId, requestData.data, requestData?.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruises, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseLineOptionsThunk = createAsyncThunk<CruiseLineType[], {name: string, type?: number, source: any}, AsyncThunkConfig>(
  'event/getCruiseLineOptions',
  async (lineData, thunkAPI) => {
    try {
      const {data, status} = await cruiseLineAPI.getCruiseLineList({name: lineData.name}, lineData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruise_lines, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue('')
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue('')
    }
  }
)

export const GetCruiseAvailableDatesThunk = createAsyncThunk<CruiseStartDateType[], {cruise_line: number, cruise_id_list?: number[]}, AsyncThunkConfig>(
  'event/getCruiseAvailableDates',
  async (requestData, thunkAPI) => {
    try {
      const {data, status} = await cruiseAPI.getCruiseAvailableDates(requestData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.start_date_locations, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default eventSlice.reducer
 